import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(0.000000, 3.000000)">
      <path
        d="M43.94,48.69v15.39h-6.93V29.54h6.93v15.48l12.56-15.48h8.36L50.62,46.66l14.64,17.41h-8.76L43.94,48.69z"
        fill="currentColor"
      />
    </g>
    <g transform="translate(11.000000, 5.000000)">
      <polygon
        id="Shape"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="39 0 0 22 0 67 39 90 78 68 78 23"
      />
    </g>
  </svg>
);

export default IconLogo;
